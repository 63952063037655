@use "sass:math"; @import "cssParams.scss";
/** @module BasicForm.module.scss
 *  @since 2022.01.28, 21:57
 *  @changed 2022.02.18, 18:34
 */

.root,
.DEBUG {
  /*!KEEP*/
  // border: 2px dashed purple;
}

.AgreementSection {
  // line-height: 1.2;
  // label {
  //   align-items: flex-start;
  //   span:not(:last-child) {
  //     margin-top: 5px;
  //   }
  // }
}

.onDark {
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
  }
  :global(.chakra-form__required-indicator),
  :global(.chakra-form__error-message) {
    // color: #fff;
    color: $secondaryColor;
  }
  :global(.chakra-form__helper-text) {
    color: #fff;
    opacity: 0.5;
  }
}
