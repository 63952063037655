@use "sass:math"; @import "cssParams.scss";
/** @module PageSectionHeader.module.less
 *  @since 2022.01.04, 20:38
 *  @changed 2022.01.04, 20:38
 */

.root,
.DEBUG {
  /*!KEEP*/
  // border: 2px dashed purple;
}

.isDark {
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
  }
}
