@use "sass:math"; @import "cssParams.scss";
/** @module GenericPageLayout.module.scss
 *  @since 2022.02.18, 17:13
 *  @changed 2022.02.18, 17:13
 */

.root,
.MainContainer,
.DEBUG {
  /*!KEEP*/
  // border: 2px dashed purple;
}

.isDark {
  background-color: $primaryColor;
}
