@use "sass:math"; @import "cssParams.scss";
/** @module PageSection.module.scss
 *  @since 2022.01.04, 20:38
 *  @changed 2022.01.04, 20:38
 */

.root,
.DEBUG {
  /*!KEEP*/
  // border: 2px dashed purple;
}

.withTopSpace {
  padding-top: 80px;
}

.isDark {
  background-color: $primaryColor;
}
