@use "sass:math"; @import "cssParams.scss";
/** @module FooterWithContacts.module.scss
 *  @since 2022.01.04, 20:38
 *  @changed 2022.01.22, 21:36
 */

.root,
.Wrapper,
.FlexWrapper,
.LogoInfoSocials,
.LogoInfo,
.SocialsBlock,
.SocialsBar,
.LogoBox,
.InfoBox,
.Column,
.isDark,
.DEBUG {
  /*!KEEP*/
  // border: 2px dashed purple;
}

.ColumnItem {
  margin: 3px 0;
}
